import React from "react"
import {Link} from "gatsby";

const Curriculo = ({data}) => (
    
    <section className="pt-6 pb-9 pt-md-8">
        <div className="container pb-6 pb-md-8">
            <div className="row align-items-center">
                <div className="col-12 col-md">

                    {/** Heading */}
                    <h3 className="mb-1 display-5 spacing font-weight-bold">
                        {data.frontmatter.heading}
                    </h3>

                    {/** Text */}
                    <p className="font-size-lg text-muted mb-6 mb-md-0 spacing">
                        {data.frontmatter.text}
                    </p>

                </div>
                <div className="col-12 col-md-auto">

                    <Link to="#!" data-toggle="modal" data-target="#cv" className="btn btn-primary spacing lift">
                        <i className="fe fe-upload"/> {data.frontmatter.send}
                    </Link>

                </div>
            </div>
            {/** / .row */}
        </div>
        {/** / .container */}
    </section>

)

export default Curriculo
