import React from "react"
import Breadcrumbs from "../../components/common/breadcrumbs";
import JobsFeed from "../../components/jobs/jobs-feed";
import Curriculo from "../../components/curriculo";
import ModalCurriculo from "../../components/modals/modal-curriculo";
import SEO from "../../components/common/seo";
import LayoutSecondary from "../../components/common/layout-secondary";
import { graphql } from 'gatsby'

const breadcrumbs = [
    {
        text: 'Talento',
        slug: '../',
    },
    {
        text: 'Únete a Tecalis',
        active: true,
    },
]

const JoinTecalisPage = ({data, pageContext}) => (
    <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
        <SEO title="Únete a Tecalis - Tecalis"
             description={"Formación y trabajo van de la mano. Queremos ayudar y contribuir a la formación práctica de estudiantes apasionados y motivados por la tecnología."}/>
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <JobsFeed data={data.jobsFeed} departments={data.departments} pageContext={pageContext}/>
        <Curriculo data={data.curriculo}/>
        <ModalCurriculo data={data.modalCurriculo}/>
    </LayoutSecondary>
)

export default JoinTecalisPage

export const joinPageQuery = graphql
    `query($lang: String) {
        jobsFeed: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "people/join-tecalis/jobs-feed"
                }
            }) {
            frontmatter {
                heading
                department
                office
                offers
                searchPlaceholder
            }
        }
        departments: allStrapiDepartment(
            filter:{
                locale: { eq: $lang }
            }) {
            edges {
                node {
                    id
                    title
                }
            }
        }
        curriculo: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "people/join-tecalis/curriculo"
                }
            }) {
            frontmatter {
                heading
                text
                send
            }
        }
        modalCurriculo: markdownRemark(
            frontmatter: {
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "people/join-tecalis/curriculo-form"
                }
            }) {
            frontmatter {
                heading
                nameField
                namePlaceholder
                email
                emailPlaceholder
                phone
                phonePlaceholder
                file
                fileButton
                search
                privacyStart
                privacyLink
                privacyText
                conditionsLink
                conditionsText
                send
            }
        }
        cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
}`;
