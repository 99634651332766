import React from "react"
import {Link} from "@reach/router";
import {gtagCustomEvent} from "../utils/global-functions";

const CurriculoForm = ({data}) => {

    const handleSubmit = async () => {
        gtagCustomEvent("curriculum.sent", {
            from: window.location.href,
            time: new Date().toString()
        })
    }

    return (
        <form className="mb-6" method="post" action={process.env.SPIRIT_URL + 'curriculum-form'}
              encType='multipart/form-data'
              id={'curriculo-form'}
              onSubmit={handleSubmit}
        >

            <div className="row">
                <div className="col-sm-4">
                    {/** Nombre */}
                    <div className="form-group">
                        <label className="sr-only" htmlFor="name">
                            {data.frontmatter.nameField}
                        </label>
                        <input type="text" className="form-control spacing" id="name" name="curriculumName" required
                               placeholder={data.frontmatter.namePlaceholder}/>
                    </div>
                </div>

                <div className="col-sm-4">
                    {/** Email */}
                    <div className="form-group">
                        <label className="sr-only" htmlFor="email">
                            {data.frontmatter.email}
                        </label>
                        <input type="email" className="form-control spacing" id="email" name="curriculumEmail" required
                               placeholder={data.frontmatter.emailPlaceholder}/>
                    </div>
                </div>

                <div className="col-sm-4">
                    {/** Teléfono */}
                    <div className="form-group">
                        <label className="sr-only" htmlFor="phone">
                            {data.frontmatter.phone}
                        </label>
                        <input type="text" className="form-control spacing" id="phone" name="curriculumPhone" required
                               placeholder={data.frontmatter.phonePlaceholder}/>
                    </div>
                </div>
            </div>

            {/** Adjuntar */}
            <div className="form-group">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h5>{data.frontmatter.file}</h5>
                        <span className="color-tecalis js-file-name" style={{"fontSize": 15}}/>
                    </div>
                    <small className="text-muted">
                        <div className="upload-btn-wrapper">
                            <button className="btn-upload">{data.frontmatter.fileButton}</button>
                            <input type="file" name="curriculumFile"/>
                        </div>
                    </small>
                </div>
            </div>
            <br/>

            <div className="form-group">
                <div className="checkbox icheck-primary spacing">
                    <input type="checkbox" id="primary" name="contactConditions" required/>
                    <label htmlFor="primary">{data.frontmatter.privacyStart}&nbsp;
                        <Link to={data.frontmatter.privacyLink} className="font-weight-bold">
                            {data.frontmatter.privacyText}</Link>
                        &nbsp;y&nbsp;
                        <Link to={data.frontmatter.conditionsLink} className="font-weight-bold">
                            {data.frontmatter.conditionsText}</Link>
                        .</label>
                </div>
            </div>

            {/** Submit */}
            <div align="center">
                <br/>
                {/*<button className="btn btn-primary g-recaptcha"*/}
                {/*        data-sitekey={process.env.RECAPTCHA_KEY} type="submit">*/}
                {/*    {data.frontmatter.send} <i className="fe fe-arrow-right"/>*/}
                {/*</button>*/}

                <button className="btn btn-primary g-recaptcha"
                        data-sitekey={process.env.RECAPTCHA_KEY}
                        data-callback='checkCaptchaForm'
                        data-action='submit'>{data.frontmatter.send} <i className="fe fe-arrow-right"/>
                </button>
            </div>

        </form>

    )
}

export default CurriculoForm
