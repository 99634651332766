// import React, {useState} from "react"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import JobPost from "./job-post";

const JobsFeed = ({data, departments, pageContext}) => {

    const dataJobs = useStaticQuery(graphql`
        query {
            offers: allStrapiJobOffer {
                edges {
                    node {
                        id
                        title
                        experience
                        requirements
                        offer
                        look_for
                        fields{
                            slug
                        }
                        office{
                            name
                        }
                    }
                }
            }
             offices: allStrapiOffice(
                sort: {
                  fields: [name]
                  order: ASC
                }
            ) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    `);

    // const allJobs = graphQuery.offers.edges
    // const emptyQuery = ""
    // const [state, setState] = useState({
    //     filteredData: [],
    //     query: emptyQuery,
    // })
    //
    // const handleInputChange = event => {
    //
    //     const { graphQuery } = graphQuery
    //     const query = event.target.value
    //     const jobs = graphQuery.offers.edges || []
    //     const filteredData = jobs.filter(job => {
    //
    //         const { title} = job.node
    //         return (
    //             title.toLowerCase().includes(query.toLowerCase())
    //         )
    //     })
    //
    //     console.log('ALLJOBS');
    //     console.log(allJobs);
    //
    //     setState({
    //         query,
    //         filteredData,
    //     })
    // }
    //
    // console.log('ALLJOBS');
    // console.log(allJobs);
    //
    // const { filteredData, query } = state
    // const hasSearchResults = filteredData && query !== emptyQuery
    // let dataJobs = graphQuery;
    // if (hasSearchResults) {
    //     dataJobs.offers.edges = filteredData
    //     // console.log('filtered');
    //     // console.log(allJobs);
    //     // console.log(dataJobs);
    // } else {
    //     dataJobs.offers.edges = allJobs
    //     // console.log('nofilter');
    //     // console.log(allJobs);
    //     // console.log(dataJobs);
    // }
    return (

        <section className="bg-grey py-8 py-md-11 mb-9">
            {/** Content */}
            <div className="container">
                {/** Heading */}
                <h1 className="display-4 font-weight-bold spacing title-feed-2">
                    {data.frontmatter.heading}
                </h1>
                <br/>

                {/** FILTROS OFERTA */}
                {/*<div className="row align-items-center">*/}
                {/*    <div className="col-12 col-md-6">*/}
                {/*        <div className="form-group mb-5">*/}
                {/*            <input type="text" className="form-control  spacing" aria-label=""*/}
                {/*                   // onChange={handleInputChange}*/}
                {/*                   placeholder={data.frontmatter.searchPlaceholder}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-12 col-md-3">*/}
                {/*        <div className="form-group mb-5">*/}

                {/*            /!** Select *!/*/}
                {/*            <select className="form-control spacing" id="exampleFormControlSelect1">*/}
                {/*                <option value="">{data.frontmatter.department}</option>*/}
                {/*                {departments.edges.map((document, i) => (*/}
                {/*                    <option key={i} value={data.frontmatter.department}>{document.node.title}</option>*/}
                {/*                ))}*/}
                {/*            </select>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-12 col-md-3">*/}
                {/*        <div className="form-group mb-5">*/}

                {/*            /!** Select *!/*/}
                {/*            <select className="form-control spacing" id="exampleFormControlSelect2"*/}
                {/*                // onChange={e => filterPosts(e.target.value)} value={selected}*/}
                {/*            >*/}
                {/*                <option value="all">{data.frontmatter.office}</option>*/}
                {/*                {dataJobs.offices.edges.map((document, i) => (*/}
                {/*                    <option key={i}>{document.node.name}</option>*/}
                {/*                ))}*/}
                {/*            </select>*/}

                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}
                <br/>
                {/**./ FILTROS OFERTA */}

                <div className="row align-items-center">
                    <div className="col-lg-12" data-aos="fade-down">

                        <div className="card card-border border-success shadow-light-lg mb-6 mb-md-8">
                            <div className="card-body">

                                {/** Heading */}
                                <h6 className="text-uppercase color-tecalis d-inline-block mb-5 mr-1">
                                    {data.frontmatter.offers}
                                </h6>

                                {/** Badge */}
                                <span className="badge badge-rounded-circle badge-primary-soft">
                                    {/*<span>{dataJobs.offers.edges.length}</span>*/}
                                </span>

                                <div className="list-group list-group-flush">
                                    {/** List group */}
                                    {dataJobs.offers.edges.map((document, i) => (
                                        <JobPost jobPost={document.node} pageContext={pageContext} key={i}/>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            {/** / .container */}
        </section>

    )
}

export default JobsFeed
