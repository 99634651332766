import React from "react"
import CurriculoForm from "../curriculo-form";

const ModalCurriculo = ({data}) => (

    <div className="modal fade" id="cv" tabIndex="-1" role="dialog" aria-labelledby="e" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="card">

                    {/** Close */}
                    <button type="button" className="modal-close close text-white" data-dismiss="modal"
                            aria-label="Close">
                        <span aria-hidden="true" className="color-tecalis">×</span>
                    </button>

                    {/** Body */}
                    <div className="card-body">

                        {/** Heading */}
                        <br/>
                        <h2 className="mb-0 font-weight-bold text-center spacing" id="">
                            {data.frontmatter.heading}
                        </h2><br/>

                        {/** Form */}
                        <CurriculoForm data={data}/>
                    </div>
                </div>
            </div>
        </div>
    </div>

)

export default ModalCurriculo
