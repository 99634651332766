import React from "react"
import {Link} from "gatsby";

const JobPost = ({jobPost}) => {

    return (


        <div className="list-group-item d-flex align-items-center">
            {/** AQUI OFERTA */}
            {/** Oferta */}
            <div className="mr-auto">

                <Link to={jobPost.fields.slug}>
                    {/** Titulo de la oferta */}
                    <p className="font-weight-bold mb-1 spacing">
                        {jobPost && jobPost.title}
                    </p>
                </Link>

                {/** Descripción básica */}
                <p className="font-size-sm text-muted mb-0 spacing">
                    {jobPost && jobPost.experience}
                </p>

            </div>

            {/** Arrow */}
            <div className="badge badge-rounded-circle badge-success-soft ml-4">
                <Link to={jobPost.fields.slug}>
                    <i className="fe fe-arrow-right color-tecalis"/>
                </Link>
            </div>
        </div>

    )
}

export default JobPost
